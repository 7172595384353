var InvitedList = [
  {
    id: 0,
    name: 'Cynthia Rudin',
    image: 'cynthia',
    affiliation: 'Duke University',
    link: 'https://users.cs.duke.edu/~cynthia/',
  },
  {
    id: 1,
    name: 'Alan Fern',
    image: 'alan',
    affiliation: 'Oregon State University',
    link: 'https://eecs.oregonstate.edu/people/fern-alan',
  },
  {
    id: 2,
    name: 'Ofra Amir',
    image: 'ofra',
    affiliation: 'Technion',
    link: 'https://scholar.harvard.edu/oamir/home',
  },
];

var MemberListCache = [
  {
    id: 0,
    name: 'Claudia V. Goldman',
    image: 'claudia',
    affiliation: 'General Motors',
    link: 'https://il.linkedin.com/in/claudiagoldman',
  },
  {
    id: 1,
    name: 'Daniele Magazzeni',
    image: 'dan',
    affiliation: 'J.P. Morgan AI Research',
    link: 'https://uk.linkedin.com/in/danielemagazzeni',
  },
  {
    id: 2,
    name: 'Jeremy Frank',
    image: 'frank',
    affiliation: 'NASA Ames',
    link: 'https://ti.arc.nasa.gov/profile/frank/',
  },
  {
    id: 3,
    name: 'Rick Freedman',
    image: 'rick',
    affiliation: 'SIFT',
    link: 'https://www.sift.net/staff/richard-freedman',
  },
  {
    id: 4,
    name: 'Tathagata Chakraborti',
    image: 'tathagata',
    affiliation: 'IBM Research AI',
    link: 'http://tchakra2.com',
  },
];

var PCListCache = [
  {
    id: 0,
    name: 'Sarath Sreedharan',
    image: 'sarath',
    affiliation: 'Arizona State University',
    link: 'http://www.public.asu.edu/~ssreedh3/',
  },
  {
    id: 1,
    name: 'Anagha Kulkarni',
    image: 'anagha',
    affiliation: 'Arizona State University',
    link: 'https://anaghak.github.io/',
  },
  {
    id: 2,
    name: 'Sailik Sengupta',
    image: 'sailik',
    affiliation: 'Arizona State University',
    link: 'https://sailik1991.github.io/',
  },
  {
    id: 3,
    name: 'Raymond Sheh',
    image: 'raymond',
    affiliation: 'National Institute of Standards and Technology',
    link: 'https://www.linkedin.com/in/raymondsheh',
    overflow: true,
  },
  {
    id: 4,
    name: 'Pascal Bercher',
    image: 'pascal',
    affiliation: 'Australian National University',
    link: 'https://cecs.anu.edu.au/people/pascal-bercher',
  },
  {
    id: 5,
    name: 'Anna Collins',
    image: 'anna',
    affiliation: 'Kings College London',
    link: 'https://www.linkedin.com/in/annacollinsf70513a151/',
  },
  {
    id: 6,
    name: 'Silvia Tulli',
    image: 'silvia',
    affiliation: 'Sorbonne University',
    link: 'https://silviatulli.com/',
  },
  {
    id: 7,
    name: 'Ramon Fraga Pereira',
    image: 'ramon',
    affiliation: 'Pontifical Catholic University of Rio Grande do Sul',
    link: 'https://ramonpereira.github.io/',
    overflow: true,
  },
  {
    id: 8,
    name: 'David Smith',
    image: 'dave',
    affiliation: '',
    link: 'http://psresearch.xyz/',
  },
  {
    id: 9,
    name: 'Mauricio Magnaguagno',
    image: 'mauricio',
    affiliation: 'Pontifical Catholic University of Rio Grande do Sul',
    link:
      'http://buscatextual.cnpq.br/buscatextual/visualizacv.do?id=K8131237J7',
    overflow: true,
  },
  {
    id: 10,
    name: 'Joseph Kim',
    image: 'joe',
    affiliation: 'Invitae',
    link: 'http://people.csail.mit.edu/joseph_kim/',
  },
  {
    id: 11,
    name: 'Prashan Madumal',
    image: 'prashan',
    affiliation: 'University of Melbourne',
    link: 'https://prashanm.com/',
  },
  {
    id: 12,
    name: 'Benjamin Wright',
    image: 'benw',
    affiliation: 'Naval Research Laboratory',
    link: 'https://khemlani.net/lab/',
  },
  {
    id: 13,
    name: 'Ofra Amir',
    image: 'ofra',
    affiliation: 'Technion',
    link: 'https://scholar.harvard.edu/oamir/home',
  },
  {
    id: 14,
    name: 'Rebecca Eifler',
    image: 'rebecca',
    affiliation: 'Saarland University',
    link: 'http://fai.cs.uni-saarland.de/eifler/',
  },
  {
    id: 15,
    name: 'Stylianos Vasileiou',
    image: 'stylianos',
    affiliation: 'Washington University',
    link: 'https://thestlucas.com/',
  },
  {
    id: 16,
    name: 'Kartik Talamadupula',
    image: 'kartik',
    affiliation: 'IBM Research AI',
    link:
      'https://researcher.watson.ibm.com/researcher/view.php?person=us-krtalamad',
  },
  {
    id: 17,
    name: 'Michael Cashmore',
    image: 'michael',
    affiliation: 'University of Strathclyde',
    link: 'http://personal.strath.ac.uk/michael.cashmore/',
  },
  {
    id: 18,
    name: 'Benjamin Krarup',
    image: 'ben',
    affiliation: 'Kings College London',
    link: 'https://uk.linkedin.com/in/benjamin-krarup',
  },
  {
    id: 19,
    name: 'Maayan Shvo',
    image: 'maayan',
    affiliation: 'University of Toronto',
    link: 'http://www.cs.toronto.edu/~maayanshvo/',
  },
  {
    id: 20,
    name: 'Felix Lindner',
    image: 'felix',
    affiliation: 'Ulm University',
    link: 'https://www.uni-ulm.de/en/in/ki/inst/staff/felix-lindner/',
  },
];

export { MemberListCache, PCListCache, InvitedList };
